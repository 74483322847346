<template>
  <div class="login-page">
    <b-container v-show="isLoggedIn" fluid="md">
      <h1>{{ $t("pages.login.loggedIn") }}</h1>
      <b-row>
        <b-col>{{ $t("pages.login.loggedInAs") }} {{ name }}</b-col>
      </b-row>
    </b-container>
    <b-container v-show="!isLoggedIn" fluid="md" @keyup.enter="login">
      <h1>{{ $t("pages.login.login") }}</h1>
      <b-row class="form-group">
        <b-col cols="4">
          <label for="user-name">{{ $t("pages.login.username") }}</label>
          <b-form-input
            id="user-name"
            v-model="username"
            :autofocus="true"
            type="text"
            placeholder="Ange ditt användarnamn"
          />
        </b-col>
      </b-row>
      <b-row class="form-group">
        <b-col cols="4">
          <label for="user-password">{{ $t("pages.login.password") }}</label>
          <b-form-input
            id="user-password"
            v-model="passphrase"
            type="password"
            placeholder="Lösenord"
          />
        </b-col>
      </b-row>
      <b-row class="form-group form-group-buttons">
        <b-col>
          <b-button
            :disabled="loginButtonDisabled"
            type="submit"
            variant="primary"
            @click="login"
          >
            {{ $t("pages.login.login") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="form-group">
        <b-col>
          <p v-show="incorrectLogin" v-t="'pages.login.failedLogin'"></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      passphrase: "",
      incorrectLogin: false
    };
  },
  metaInfo() {
    return {
      title: this.$t("pages.login.login")
    };
  },
  computed: {
    isLoggedIn() {
      return (
        this.$root.user !== undefined && this.$root.user.username !== undefined
      );
    },
    loginButtonDisabled() {
      return !(
        this.username !== "" &&
        this.username.length > 4 &&
        this.passphrase !== "" &&
        this.passphrase.length > 4
      );
    },
    name: function() {
      return this.isLoggedIn
        ? this.$root.user.firstname + " " + this.$root.user.lastname
        : "";
    }
  },
  methods: {
    login() {
      //Create a token from server, if successful fire an event with the token. Else fail login attempt and reset login form
      if (!this.loginButtonDisabled) {
        this.incorrectLogin = false;
        let BASE_URL = process.env.VUE_APP_BASE_URL; // || "http://localhost:8080";
        this.axios
          .get(BASE_URL + "/user/token", {
            auth: {
              username: this.username,
              password: this.passphrase
            }
          })
          .then(response => {
            this.$eventBus.$emit("login-action", {
              token: response.data.token
            });
          })
          .catch(() => {
            this.incorrectLogin = true;
          })
          .finally(() => {
            //Reset the credentials, keep username if login attempt failed
            if (!this.incorrectLogin) {
              this.username = "";
            }
            this.passphrase = "";
          });
      }
    }
  }
};
</script>

<style scoped></style>
